@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;600&display=swap');
*,  
*::before,
*::after {
    box-sizing: border-box;
}

 * {
     margin: 0;
 }

html,
body,
#root{
    height: 100%;
    font-family: 'Poppins',
        sans-serif;
}

img,
picture,
video,
canvas,
svg {
    display: block;
    max-width: 100%;
}

input,
button,
textarea,
select {
    font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
    overflow-wrap: break-word;
}

.react-tabs__tab-panel.selected-panel{
    display: flex;
    flex-direction: column;
    flex:1 0 auto;
}