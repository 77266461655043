.header {
  padding: 1rem;
}
.controls {
  display: flex;
  column-gap: 1rem;
  align-items: center;
}
.loadingContainer {
  display: flex;
}
.vehicleName {
  text-transform: lowercase;
  display: inline-block;
  vertical-align: middle;
  margin-right: 1rem;
}

.reactSwitch {
  vertical-align: middle;
  margin-left: 5px;
}

.mockButton {
  margin-right: 1rem;
}

.submitButtons {
  display: inline-block;
  margin-left: 1rem;
}
.form {
  display: inline;
}
@media (max-width: 450px) {
  .controls {
    flex-direction: column;
    row-gap: 1rem;
    align-items: start;
  }
  .submitButtons {
    margin-left: 0;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
}
