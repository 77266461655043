.pushable {
  position: relative;
  border: none;
  background: transparent;
  padding: 0;
  cursor: pointer;
  outline-offset: 4px;
  transition: filter 250ms;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  margin-right: 0.5rem;
}

.shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: hsl(0deg 0% 0% / 0.25);
  will-change: transform;
  transform: translateY(2px);
  transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
}

.edge {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: linear-gradient(
    to left,
    hsl(340deg 100% 16%) 0%,
    hsl(340deg 100% 32%) 8%,
    hsl(340deg 100% 32%) 92%,
    hsl(340deg 100% 16%) 100%
  );
}

.edge.hesitation {
  background: linear-gradient(
    to left,
    hsl(225deg 100% 2%) 0%,
    hsl(225deg 100% 10%) 8%,
    hsl(225deg 100% 10%) 92%,
    hsl(225deg 100% 2%) 100%
  );
}

.edge.swimming {
  background: linear-gradient(
    to left,
    hsl(180deg 100% 5%) 0%,
    hsl(180deg 100% 20%) 8%,
    hsl(180deg 100% 20%) 92%,
    hsl(180deg 100% 5%) 100%
  );
}

.edge.other {
  background: linear-gradient(
    to left,
    hsl(120deg 100% 2%) 0%,
    hsl(120deg 100% 20%) 8%,
    hsl(120deg 100% 20%) 92%,
    hsl(120deg 100% 2%) 100%
  );
}

.front {
  display: block;
  position: relative;
  padding: 12px 10px;
  border-radius: 12px;
  color: white;
  background: hsl(345deg 100% 47%);
  will-change: transform;
  transform: translateY(-8px);
  transition: transform 600ms cubic-bezier(0.3, 0.7, 0.4, 1);
}

.front.hesitation {
  background: hsl(221deg 90% 49%);
}

.front.swimming {
  background: hsl(176deg, 94%, 32%);
}
.front.other {
  background: hsl(127deg, 45%, 39%);
}

.pushable:active .front {
  transform: translateY(-2px);
  transition: transform 34ms;
}

.pushable:active .shadow {
  transform: translateY(1px);
  transition: transform 34ms;
}

.pushable:focus:not(:focus-visible) {
  outline: none;
}

@media (max-width: 768px) {
  .front {
    padding: 6px;
  }
}

@media (max-width: 450px) {
  .pushable:first-of-type {
    margin-bottom: 0.8rem;
  }
}
