.overallStatus{
    background-color: #f7f7f7;
    display: flex;
    justify-content: center;
    padding: 0.5rem;
    column-gap: 0.5rem;
}

.overallStatusIcon{
    width: 120px;
    clip-path: circle(20px);
    display: flex;
    justify-content: center;
    align-items: center;
}

.overallStatusText{
    display: flex;
    justify-content: center;
    align-items: center;
}

.overallStatusText >p{
    color: #242e42;
}

.scaleSection{
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 1rem;
    padding: 1.5rem 0.5rem 1rem 0.5rem;
}

.explanationText{
    color:#242e42;
    text-align: center;
}

.scaleContainer{
    position: relative;
}
.scaleContainer.loading{
    width: 300px;
}
.pointerContainer{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: left 1s;
}

.pointer{
    position: absolute;
    width:20px;
    height:15px;
    background-color: #2f3134;
    clip-path: polygon(0% 0%,50% 100%,100% 0%);
}
.pointer.comfort{
    top:30px;
    background-color: #7d1450;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
}
.pointerLabel{
    font-size: 0.6rem;
    white-space: nowrap;
    position: absolute;
    bottom: 8px;
}

.pointerLabel.comfort {
    font-size: 0.6rem;
    white-space: nowrap;
    position: absolute;
    top:45px;
}

.currentStatusScale{
    display: flex;
    border-radius: 8px;
}
.blockContainer{
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

.colorBlock{
    width: 100px;
    height: 40px;
}
.blockContainer > p{
    font-size: 0.8rem;
    color:#8a8a8f;
    text-align: center;
}
.colorBlock.left{
    border-radius: 8px 0 0 8px;
}

.colorBlock.right{
    border-radius: 0 8px 8px 0;
}

@media (min-width:480px) {
    .overallStatus{
        height: 80px;
    } 
}

@media (max-width:480px){
    .explanationText{
        font-size: 12.5px;
        line-height: 1rem;
    }

    .overallStatusText>p {
        line-height: 20px;
    }

}