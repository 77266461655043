
.liveScreenContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
}
.tabContainer{
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
}

.tablistContainer{
    margin:0;
    padding:0 1rem;
    border-bottom:1px solid #aaa;
}

.loadingContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}