.container{
    height: 100%;
    background: linear-gradient(#58d3cd, #369040);
    color:#f2f6ff;
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 2rem;
    text-align: center;
}

.selectContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.selectInput{
    padding: 0.5rem;
    border-radius: 5px;
}
.continueButton{
    margin-top: 1rem;
    background-color: #0b2058;
    border-radius: 8px;
    border: none;
    padding: 0.5rem;
    color:#f2f6ff;
}


.appName{
    font-size: 3rem;
}